@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

div[class].modal {
  width: 620px;
  height: 400px;
  min-height: 200px;
}
.form {
  max-height: 200px;
  height: 400px;

  header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: $color-dark-blue;
  }

  .div-wrapper{
    display: flex;
    justify-content: space-between;
    width: 150px;

    button {
      background-color: inherit;
      outline: 0 !important;
    }
  }
  .form-row {
    display: flex;
    justify-content: space-between;
  }
  .input-wrapper {
    width: 88px;
    margin: 0 12px 0;
  }
.input-label {
  font-size: 12px;
  color: $grey;
  padding-bottom: 7px;
}

  .red-note {
    padding-left: 5px;
    display: inline;
    color: $invalid-red
  }

  .balance-info {
    color: $dark-grey;
    padding-top: 26px;
    font-weight: bold;
    font-size: 14px;
  }

  .current-balance{
    margin-left: 10px;
    color: $green;
    font-size: 18px;
  }

  .comments-wrapper {
  padding-top: 20px;
  }

  .form-row-btn {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 15px;
  }

  .div-btn-wrapper {
    width: 210px;
  }
}

.confirm-modal {
  text-align: center;

  header {
    font-weight: bold;
    font-size: 20px;
    color: $color-dark-blue;
  }
  section {
    padding: 20px 0 40px;
  }
  .button-content {
    padding: 0 30px;
  }
}
.points-edit-comments{
  textarea {
    height: 140px;
  }
}
