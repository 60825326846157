@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.btn {
  background: $btn-bg;
  box-shadow: 0 5px 15px $box-shadow-btn;
  border-radius: 14px;
  font-weight: 600;
  font-size: 16px;
  height: 40px;
  text-align: center;
  color: $btn-color;
  outline: 0;
  padding: 6px 15px 8px;

  &:hover {
    background: $btn-bg-hover;
    box-shadow: 0 4px 10px $box-shadow-btn-hover;
    transition: color 0.3s;
    outline: 0;
  }

  &:active {
    background: $btn-bg-hover;
    box-shadow: 0 0 8px $box-shadow-btn-active;
    outline: 0;
  }

  &.btn-outline {
    color: $btn--color-outline;
    border: 1px solid $btn--color-outline;
    background: $btn-bg-outline;
    box-shadow: 0 5px 15px $box-shadow-btn-outline;

    &:hover {
      background: $btn-bg-hover;
      box-shadow: 0 5px 10px $box-shadow-btn-hover;
      color: $btn-color;
      transition: color 0.2s;
    }

    &:active {
      background: $btn-bg-active;
      box-shadow: 0 0 10px $box-shadow-btn-active;
      color: $btn-color;
    }
  }

  &.btn-secondary {
    border-radius: 10px;
    box-shadow: 0 5px 15px $box-shadow-btn-secondary;
    height: 30px;
    font-size: 12px;

    &:hover {
      background: $btn-bg-hover;
    }
    &:active {
      background: $btn-bg-active;
      box-shadow: 0 0 8px $box-shadow-btn-active;
    }

    &.btn-outline {
      box-shadow: 0 5px 10px $box-shadow-btn-secondary-outline;

      &:hover {
        box-shadow: 0 5px 10px $box-shadow-btn-secondary;
      }

      &:active {
        box-shadow: 0 0 8px $box-shadow-btn-active;
      }
    }
  }

  &.btn-small {
    height: 24px;
    box-shadow: 0 5px 15px rgba(55, 55, 237, 0.25);
    font-size: 12px;

    &:hover {
      background: $btn-bg-hover;
    }

    &:active {
      background: $btn-bg-active;
      box-shadow: 0 0 8px $box-shadow-btn-active;
    }
  }

  &.btn-disabled,
  &.btn-disabled:hover,
  &.btn-disabled:active {
    background: $btn-color-disabled !important;
    box-shadow: none !important;
    border-color: $btn-color-disabled !important;
    color: $white !important;

    &.btn-outline,
    &.btn-outline:hover,
    &.btn-outline:active {
      background: $btn-bg-outline !important;
      border-color: $btn-color-disabled !important;
      color: $btn-color-disabled !important;
      box-shadow: none !important;
    }
  }



  &.btn-size-default {
    width: min-content;
  }

  &.btn-size-md {
    //width: 150px;
  }

  &.btn-size-sm {
    //width: 72px;
  }

  &.btn-size-xs {
    width: 61px;
  }

  &.btn-size-percent {
    width: 100%;
  }
}

.btn-link {
  background: none;
  border: none;
  outline-style: none;
  text-align: center;
  color: $blue;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}

.btn-link:active {
  outline: none;
}

.btn-discharge {
  height: 30px;
  margin-top: 18px;
  margin-right: $size-md;
  padding: 0;
  border: none;
  color: inherit;
  background-color: transparent;
  outline: none;
}

.btn-discharge:hover svg path {
  fill: $discharge-btn-hover;
}

.btn-discharge:hover svg circle {
  fill: $discharge-btn-hover;
}

.btn-discharge:active svg path {
  fill: $discharge-btn-active;
}

.btn-discharge:active svg circle {
  fill: $discharge-btn-active;
}

.humburger-btn {
  margin-left: 40px;
  border-style: none;
  background: none;
  outline-style: none;
}

.btn-request {
  width: 150px;
  height: 30px;

  background: linear-gradient(180deg, #1F1FEB 0%, #3737ED 100%);
  box-shadow: 0px 5px 15px rgba(31, 31, 235, 0.25);
  border-radius: 10px;
}

.container-spinner{
  //border: 1px solid red;
  display: flex;
  justify-content: center;
}
