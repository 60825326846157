@import '../../../../../assets/style/colors';

.container {
  position: relative;
  text-align: center;
  width: 100%;
}

.options {
  display: flex;
  cursor: pointer;
  padding: 10px;
  justify-content: center;
  .circle {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: $blue;
    margin: 2px;
    z-index: 2;
  }
}


.openOptions {
  height: 32px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  visibility: visible;
  padding-left: 8px;
  padding-top: 4px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  & span i svg {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }

  & span {
    margin-right: 12px;
  }

  & span:last-child {
    margin-right: 0;
  }
}

.open-options-inline {
  box-shadow: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  z-index: 10000;
}
.openOptionsWrapper {
  position: absolute;
  bottom: -23px;
  left: 50%;
  transform: translate(-50%, -25%);
  z-index: 10001;
}

.openOptionsWrapper .openOptions .tooltip {
  border-radius: 10px;
  padding: 5px 10px;
  height: 25px;
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  background-color: $white;
  color: $dark-grey;
  box-shadow: $tooltip-shadow;
}

.tooltip-points {
  border: 1px solid $points_icon;
}

.tooltip-time {
  border: 1px solid $blue;
}

.tooltip-update {
  border: 1px solid $green;
}

.tooltip-discharge {
  border: 1px solid $red;
}

.tooltip-restore {
  border: 1px solid $blue;
}
