@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.row {
  display: flex;
  justify-content: space-between;
}

.mrg-b {
  margin-bottom: 40px;
}

.right-block,
.left-block {
  width: min-content;
}

.left-block > div,
.right-block > div {
  padding-top: 20px;
}

.title {
  color: $blue;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.01em;
}

.day-off-currentBalance,
.day-off-maximalValue {
  min-width: 156px;
  width: 20%;
}

.day-off-currentBalance {
  margin-right: 32px;
}
/*First terminating day*/
.div-wrapper{
  width: 140px;
  &.time-tracker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    min-width: 156px;
    width: 20%;
  }
  .checkbox-label {
    cursor: pointer;
    div {
      padding-left: 20px;
    }
  }
  &.checkbox-container {
    min-width: 156px;
    width: 20%;
    align-self: center;
    margin-right: 32px;
  }
}

.field-label {
  color: #9A9A9A;
  font-size: 12px;
  line-height: 18px;
}
