@import '../../../../../assets/style/colors';

.item {
  flex-basis: calc(25% - 22px);
  max-width: 264px;
  border-radius: 14px;
  padding: 24px;
  padding-bottom: 66px;
  margin-left: 22px;
  margin-bottom: 22px;
  border: 1px solid $light-grey;
  background: $white;
  position: relative;
  transition: all 0.2s;

  .noData {
    color: $grey;
  }

  &:hover {
    box-shadow: 0px 0px 20px rgba(51, 51, 51, 0.2);
  }

  img {
    width: 88px; 
    height: 88px;
    border-radius: 50%;;
  }

  h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-top: 14px;
    margin-bottom: 8px;
  }

  p {
    margin-top: 0;
    margin-bottom: 27px;
  }

  .open-profile-btn {
    position: absolute;
    bottom: 34px;
    left: 50%;
    transform: translateX(-50%);
    height: 32px;
    background: $white;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(55, 55, 237, 0.1);
    border: 1px solid $blue;
    color: $blue;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    padding: 0 15px;
    width: fit-content;
    transition: all 0.2s;

    &:hover {
      background: $blue-hover;
      color: $white;
      border-color: $blue-hover;
      cursor: pointer;
    }
  }
}