@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";

.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 0 auto;
    position: relative;
    width: 265px;
    height: 271px;
    background: url(icon1.png) rgba(55, 55, 237, 0.8);
    mix-blend-mode: multiply;
    box-shadow: 0px 0px 20px $box-shadow;
    border-radius: 14px;
    overflow: hidden;
  }

.price{
    position: absolute;
    width: 78px;
    height: 32px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    padding-top: 7px;
    color: $blue;
    background: rgba(255, 255, 255, 0.85);
    border-radius: 0px 12px 0px 14px;
    margin-right: auto;
    z-index: 2;
}

.logo{
  float: right;
  margin-right: 12px;
  svg {
    path {
      fill: $blue
    }
  }
}

.descr{
  width: 265px;
  height: 271px;
  left: 123px;
  top: 225px;
  background: rgba(17, 15, 48, 0.7);
  box-shadow: 0px 0px 20px $box-shadow;
  border-radius: 14px;
  transform: translate3d(0, 192px, 0);
  transition: all 0.25s linear;
  cursor: pointer;
}

.btn{
  position: absolute;
  width: 216px;
  height: 30px;
  left: 23px;
  top: 220px;
  background: linear-gradient(180deg, $ultra-blue 0%, $blue 100%);
  box-shadow: 0px 5px 15px $box-shadow-btn;
  border-radius: 10px;
  border-color: $ultra-blue;
  font-style: normal;
  font-weight: 600;
  font-size: $font-size-xs;
  line-height: 16px;
  text-align: center;
  color: $color-white;
  cursor: pointer;
}

.name{
  position: absolute;
  width: 216px;
  height: 52px;
  top: 14px;
  left: 23px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  color: $color-white;
  text-align: left;
  transition: all 0.25s linear;
  padding-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.descrText{
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 216px;
  height: 92px;
  left: 23px;
  top: 117px;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-xs;
  line-height: 18px;
  color: $color-white;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.container:hover .descr{
  transform: translate3d(0, 0, 0);
}

.container:hover .price{
  background: $blue;
  color: $color-white;
}

.container:hover .name{
  top: 63px;
}

.container:hover .logo{
  fill: $color-white;
  stroke: $color-white;
}
