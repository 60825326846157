@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.btn-primary{
  background: none;
  outline: 0;
  height: 18px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: $red;
  display: inline-flex;
  align-items: center;
  svg{
    margin: 2px 0 0 5px;
    height: 16px;
    width: 16px;
    circle {
      fill: $red;
    }
    rect {
      fill: $white
    }
  }
  &:hover{
    color: $pale-red;
    circle {
      stroke: $pale-red;
      fill: $pale-red;
    }
  }
  &:active {
    color: $discharge-btn-active;
    circle {
      stroke: $discharge-btn-active;
      fill: $discharge-btn-active;
    }
  }
  &:disabled{
    color: $btn-color-disabled;
    circle {
      stroke: $btn-color-disabled;
      fill: $btn-color-disabled;
    }
  }
}

.btn-outline{
  svg{
    circle {
      stroke: $red;
      fill: $white;
    }
    rect {
      fill: $red;
    }
  }
  &:hover{
    color: $pale-red;
    circle {
      stroke: $pale-red;
      fill: $white;
    }
    rect{
      fill: $pale-red;
    }
  }
  &:active {
    color: $discharge-btn-active;
    circle {
      stroke: $discharge-btn-active;
      fill: $white;
    }
    rect{
      fill: $discharge-btn-active;
    }
  }
  &:disabled{
    color: $btn-color-disabled;
    circle {
      stroke: $btn-color-disabled;
      fill: $white;
    }
    rect{
      fill: $btn-color-disabled;
    }
  }
}
