@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import '../../../../../assets/style/mixins';

.table-container{
  margin-bottom: 32px;
}
.btn-filter{
  position: relative;
}
.hidden{
  display: none;
}
.showed{
  display: block;
}
.general{
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $dark-grey;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.status{
  border-radius: 20px;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: $dark-grey;
  background: $white;
  padding: 4px 12px;
}
.orange{
  background: $orange-light;
  color: $orange;
}
.red{
  background: $light-red;
  color: $red;
}
.green{
  background: $light-green;
  color: $green;
}
.color-price{
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
}
.color-price-red{
  color: $red;
}
.color-price-green{
  color: $green;
}

.center{
  width: 100%;
  text-align: center;
  padding-right: 10px;
  font-size: 14px;
  line-height: 24px;
}

.customTitle {
  display: inline;
  position: relative;
}

.customTitle:hover:after {
  content: attr(data-title);
  color: #fff;
  background: #333;
  background: rgba(0, 0, 0, .8);
  border-radius: 5px;
  top: 30%;
  left: 20%;
  padding: 5px 15px;
  position: absolute;
  z-index: 10;
  width: 350px;
}
