@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import '../../../assets/style/mixins';

.container {
  @include container;
  position: relative;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .content-container {
    display: flex;
    justify-content: space-between;
  }

  .form-column-container {
    width: 48%;
    text-align: left;
    color: $grey;
    font-size: 12px;

    .form-row-flex-container {
      display: flex;
      justify-content: space-between;
      padding: 0 0 20px;

      .input-name {
        width: 50%;
      }

      .input-categoryId {
        width: 45%;
        border-radius: 6px;
      }

      .input-price{
        width: 50%;
      }

      .input-people {
        width: 45%;
      }
    }
  }

  .picture {
    position: relative;
    max-height: 335px;
    width: 48%;
    background-color: $light-grey;
    border-radius: 10px;

    .crop {
      position: absolute;
      height: 200px;
      width: 200px;
      top: calc(50% - 100px);
      left: calc(50% - 100px);
      border: 2px dashed $blue;
      border-radius: 14px;
    }

    input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    img {
      height: 335px;
    }

    .center {
      position: absolute;
      cursor: pointer;
      top: calc(50% - 15px);
      left: calc(50% - 15px);
      margin: 0;
    }

    .right {
      position: absolute;
      cursor: pointer;
      top: 80%;
      left: 90%;
      margin: 0;
      z-index: 9999;
    }

    .info {
      padding: 20px 0;
      position: absolute;
      text-align: center;
      top: calc(50% + 15px);
      width: 100%;
      color: $grey;
      font-size: 12px;

      .pictureInfo {
        padding-top: 2px;
      }
    }
  }

  .emptyPicture {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 335px;
    width: 48%;
    background-color: $light-grey;
    border-radius: 10px;
    z-index: 10;
  }

  .footer-btn-container {
    text-align: right;
    padding: 16px 0;

    button {
      margin-left: 20px;
      width: 210px;
      height: 40px;
    }
  }
}

.invalid {
  border: 1px solid $invalid-red;
}

.label-for-field {
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  color: $color-gray;
}

.input {
  margin-top: 7px;
}

.block-go-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  font-size: 12px;
  margin-top: 26px;
  padding-bottom: 26px;
}

.go-back {
  background: none;
  color: $blue;
  padding: 0;
  cursor: pointer;
  svg {
    margin-bottom: -2.5px;
  }
  svg path {
    stroke: $blue;
  }
  .go-back-text {
    margin-left: 8px;
    font-size: 14px;
  }
}

.red-note {
  padding-left: 5px;
  display: inline;
  color: $invalid-red
}