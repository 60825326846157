@import "../../assets/style/variables";
@import '../../assets/style/colors';

.container {
  width: 100%;
  height: 70px;
  margin: -$top-margin auto 0 auto;
  display: flex;
  justify-content: flex-end;
  color: $color-black;
  position: fixed;
  z-index: 999;
  padding: 12px 35px 12px 20px;
  background: $white;
  box-shadow: 0 0 20px $box-shadow;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  
  .points {
    display: flex;
    align-items: center;
    font-size: $font-size-sm;
    line-height: 19px;
    margin: 4px 45px 0 0;
    width: 260px;
    position: fixed;
    top: 17px;
    right: -30px;


    .points_icon {
      bottom: 4px;
      width: 24px;
      height: 24px;
      position: relative;
      margin: 0 $size-sm 0 0;
      i{
        display: block;
        width: $size-md;
        height: $size-md;
        margin-top: 2px;
        svg {
          width: 28px;
          height: 28px;
          rect{
            stroke: $blue;
          }
          path{
            fill: $blue;
          }
        }
      }
    }

    .points_desc {
      display: flex;
      flex-direction: column;

      .points_balance {
        display: inline-flex;
        justify-content: left;
        margin: 0;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;

        span {
          font-weight: 600;
          color: $color-crimson;
          padding-left: 5px;

        }
      }

      .points_currency {
        font-weight: 600;
        font-size: 10px;
        line-height: 14px;
        color: #9A9A9A;
      }

    }
  }

  & > ul {
    width: 40px;
    height: 40px;
    border-radius: 12px;
    margin-right: 20px;
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    z-index: 100;
    padding-left: 5px;
    padding-top: 5px;
    position: fixed;
    top: 14px;
    right: 270px;

    li {
      
      span {
        .icon_basket {
          display: inline-block;
          width: 23px;
          height: 23px;
          position: relative;
          cursor: pointer;


          .counter {
            position: absolute;
            text-align: center;
            width: 13px;
            height: 13px;
            font-size: 10px;
            color: $color-white;
            background: $color-crimson;
            border-radius: 50%;
            top: 0;
            right: -8px;
          }
        }
        svg path{
          fill: $blue;
        }
      }
      span.is-disabled{
        pointer-events: none;
        cursor: default;
        svg path{
          fill: $grey;
        }
        .icon_basket{
          .counter {
            background: $grey;
          }
        }
      }
    }
  }
}

.basket-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px 32px;
  position: fixed;
  top: 75px;
  right: 141px;
  width: 340px;
  height: 477px;
  background: $white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  z-index: 100;

  &::before{
    content: '';
    position: absolute;
    top: -20px;
    right: 150px;
    width: 40px;
    height: 20px;
    background: url("../../assets/images/basket-arrow.svg") no-repeat;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    background: $scroll-bar-background;
    box-shadow: inset 0 0 3px rgba(179, 179, 248, 0.2);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: $scroll-bar-thumb;
    border-radius: 5px;
    width: 6px;
    height: 64px;
  }

  .basket-header {
    display: flex;
    justify-content: space-between;

    h2 {
      display: block;
      font-style: normal;
      font-weight: bold;
      font-size: $font-size-sm;
      line-height: 19px;
      color: $color-dark-blue;
      margin: 0 0 10px 0;
    }

    svg {
      display: block;
    }

  }

  .basket-content {
    width: 100%;
    height: 300px;
    overflow: auto;
    padding: 0 10px 0 0;
  }

  .basket-footer {
    border-top: 1px solid $color-light-grey;
    font-style: normal;
    font-weight: bold;
    font-size: $font-size-sm;
    line-height: 26px;
    color: $dark-grey;
    display: flex;
    flex-direction: column;

    .basket-total-amount {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    .basket-total-amount p:last-child {
      font-size: 18px;
      color: $blue;
      display: flex;
      align-items: center;
      line-height: 18px;

      i {
        margin: 0;
        svg {
          margin-left: 10px;
          width: $size-md;
          height: $size-md;
          rect{
            stroke: $blue;
          }
          path{
            fill: $blue;
          }
        }
      }
    }

    a,
    .basket-btn {
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-md;
      line-height: 22px;
      padding: $size-sm $size-sm*5.5;
      color: $color-white;
      text-decoration: none;
      background: linear-gradient(180deg, #1F1FEB 0%, #3737ED 100%);
      box-shadow: 0px 5px 15px rgba(31, 31, 235, 0.25);
      border-radius: 14px;
    }
      .basket-btn-not-enough {
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        font-family: inherit;
      }
      .basket-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
      }
  }
}


.counter-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-sm;
  line-height: 19px;
  text-align: center;
  color: $color-black;

  button {
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;

    svg {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }

  button:disabled svg rect {
    fill: $color-gray;
  }

  button:disabled svg circle {
    stroke: $color-gray;
  }
}
.mask{
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #00000090;
  z-index: 99;
  transform: scale(2);
  overflow: hidden;
}

div.redirect-modal {
  min-height: 0 !important;
  padding: 16px 16px 32px;
  max-width: 360px;

  .btn-container {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      width: fit-content;
    }
  }

  p {
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    padding: 0 42px;
  }

  .required-info-image {
    width: 276px;
    height: 251px;
    background: url('../../assets/images/requiredInfoPopup.svg');
    margin: 40px auto 57px;
  }
}