@import '../../assets/style/colors';
@import '../../assets/style/variables';

.container {
  padding: 20px 0;
  color: $dark-grey;

  h1 {
    font-size: 20px;
    margin: 0;
    text-align: left;
    padding: 24px 0;
  }
}

.btn-container {
  padding: 40px 0;
}

.btn-extra-view {
  cursor: pointer;
  background-color: inherit;
  border: none;
  font-size: 14px;
  color: $blue;
  font-weight: 600;
}
