@import '../../../../assets/style/colors';
@import "../../../../assets/style/variables";

.container {
  padding: 24px;
  color: $dark-grey;
  text-align: left;
  border: 1px solid $light-grey;
  background: $white;
  box-shadow: 0 0 20px $box-shadow;
  border-radius: 14px;
  margin-bottom: 12px;
}

.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.reviewer {
  display: flex;
  justify-content: left;
  align-items: center;
}

.reviewer-image {
  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
}

.reviewer-name {
  padding: 0 16px;
}

.date {
  color: $grey;
  font-size: 14px;
  text-align: right;
  padding: 13px 80px 0 0;
}

.remove {
  position: absolute;
  top: 10px;
  right: 22px;
}


.description {
  font-size: 14px;
  padding-top: 20px;
}
