@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.modal {
  width: 360px;
  height: 600px;
  text-align: center;
}

.form {
  header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: $dark-grey;
    margin-bottom: 20px;
  }

  .red-note {
    padding-left: 5px;
    display: inline;
    color: $invalid-red
  }

  .comments-wrapper {
    text-align: left;
    padding-top: 20px;
    margin-bottom: 25px;
  }

  .form-row-btn {
   text-align: center;
  }

  .div-btn-wrapper {
    width: 200px;
  }
}
