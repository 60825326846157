@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import '../../../assets/style/mixins';

.container {
  width: 100%;
  padding: 0 20px;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
  .section_filter {
    margin-top: 24px;
  }

  .main {
    @include grid;
  }
}

.progress-bar-container{
  margin-top: 24px;
  .achievements-container{
    width: 100%;
  }
  .meter {
    margin: 0 auto;
    width: 99%;
    position: relative;
    height: 1px;
    background: $scroll-bar-meter;
    border-radius: 8px;
  }
  .color {
    position: absolute;
    top: -1px;
    height: 3px;
    background: linear-gradient(270deg, rgba(209, 42, 108, 0.8) 58.85%, rgba(240, 143, 181, 0) 100%);
    box-shadow: 0 0 6px rgba(240, 143, 181, 0.2);
    border-radius: 8px;
  }
  .color::after {
    @include progress-bar-pointer;
  }
  .color::before {
    @include progress-bar-pointer;
    left: calc(100% - 12px);
    top:-10px;
    border: 1px solid $light-grey;
    height: 23px;
    width: 23px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.4) 29.69%, rgba(55, 55, 237, 0) 91.15%);
    box-shadow: 0 0 10px rgba(225, 47, 117, 0.1);
  }
  .progress-bar-amount {
    text-align: left;
    padding-top: 15px;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: $dark-grey;
    padding-left: 2px;
  }
  .input-label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
  .red-text {
    font-weight: bold;
    color: $invalid-red;
  }
  .green-text {
    font-weight: bold;
    color: $green;
  }
}

.pagination {
  @include pagination;
}

.pagination-item {
  @include pagination-item;
}

.pagination-active {
  @include pagination-active
}

.pagination-item {
  @include pagination-item-svg;
}

.pagination-disabled {
  @include pagination-disabled
}

