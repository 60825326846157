@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';

.base {
  font-weight: 600;
}
.cancel {
  color: $color-dark-blue;
}
.pending {
  color: $orange;
}
.approve {
  color: $approve-green;
}
.reject {
  color: $require-error-color;
}
