@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.container {
  position: relative;
  color: $color-dark-blue;
}

.form {
  width: 100%;
  padding: 34px 40px 32px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  background: $color-white;
}

.form-row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 25px;
}

.div-wrapper {
  display: flex;
  justify-content: space-between;
  width: 177px;

  button {
    background-color: inherit;
    outline: 0 !important;
    padding-left: 0;
    padding-right: 0;
  }
}

.div-feecharger-wrapper {
  display: flex;
  justify-content: space-between;
  width: 110px;

  button {
    background-color: inherit;
    outline: 0 !important;
    padding-left: 0;
    padding-right: 0;
  }
}

.input-wrapper {
  width: 120px;
  margin: 0 12px 0;
}

.input-feecharger-wrapper {
  display: none;
}

.feecharger {
  font-weight: 600;
  font-size: 20px;
  color: $invalid-red;
}

.input-label {
  text-align: left;
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 17px;
  color: $dark-grey;
}

.achievements-amount {

  .input-label{
  font-size: 12px;
  padding-bottom: 7px;
  color: $grey;
}}

.form-row-btn {
 display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.form-row-btn .div-btn-wrapper {
  width: 150px;
}

.achievements-container{
  width: 30%;
}
.pointsFund-container {
  width: 30%;
}

.progress-bar-container {

  .meter {
    margin: 0 auto;
    width: 90%;
    position: relative;
    height: 1px;
    background: $scroll-bar-meter;
    border-radius: 8px;
  }

  .color {
    position: absolute;
    top: -1px;
    height: 3px;
    background: linear-gradient(270deg, rgba(209, 42, 108, 0.8) 58.85%, rgba(240, 143, 181, 0) 100%);
    box-shadow: 0 0 6px rgba(240, 143, 181, 0.2);
    border-radius: 8px;
  }

  .color::after {
    @include progress-bar-pointer;
  }
  .color::before {
    @include progress-bar-pointer;
    left: calc(100% - 10px);
    top:-10px;
    border: 1px solid $light-grey;
    height: 23px;
    width: 23px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.4) 29.69%, rgba(55, 55, 237, 0) 91.15%);
    box-shadow: 0 0 10px rgba(225, 47, 117, 0.1);
  }

  .progress-bar-amount {
    text-align: left;
    font-weight: bold;
    padding: 15px 0 20px;
  }

  .red-text {
    color: $invalid-red;
  }

  .green-text {
    color: $green;
  }
}

.row-btn {
  position: absolute;
  right: 0;
  top: -52px;
  display: inline-block;
  width: 50%;
  text-align: right;
  padding-right: 30px;
}

.row-btn .btn-wrapper {
  width: 210px;
  margin-left: 30px;
}
