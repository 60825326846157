@import '../assets/style/colors';
@import "../assets/style/variables";

.wrapper-for-tooltip {
  width: 100%;
}

.filter-select {
    width: 100%;

  select {
    width: 100%;
    min-width: 80px;
    height: 30px;
    border: 1px solid $blue;
    box-sizing: border-box;
    border-radius: 10px;
    background: $color-white;
    box-shadow: 0 0 20px $box-shadow;
    color: $dark-grey;
    margin: 5px 10px 0 0;
    padding: 5px 20px 5px 5px;
    background: url(../assets/images/arrow_icon.svg) no-repeat;
    background-position: calc(100% - 5px);
    font-size: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.filter-select-disable {
  display: none;
}

.desc-select {
  width: 50%;

  select {
    border: 1px solid $blue;
    width: 137px;
    height: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    background: $color-white;
    box-shadow: 0 0 20px $box-shadow;
    color: $dark-grey;
    margin: 5px 10px 0 0;
    padding: 5px 20px 5px 5px;
    appearance: none;
    background: url(../assets/images/arrow_icon.svg) no-repeat;
    background-position: calc(100% - 5px);
    font-size: 12px;
  }
}

.time-select {
  select {
    width: 72px;
    height: 30px;
    border: 1px solid $blue;
    box-sizing: border-box;
    color: $dark-grey;
    border-radius: 10px;
    background: $color-white;
    padding: 5px 20px 5px 10px;
    background: url(../assets/images/time_icon.svg) no-repeat;
    background-position: calc(100% - 5px);
    font-size: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  select[disabled] {
    border-color: $grey;
    background: url(../assets/images/time_icon_disable.svg) no-repeat;
    background-position: calc(100% - 5px);
    color: $grey;
  }
}


.tooltip{
  background-color: $require-error-color !important;
  border-radius: 6px;
  padding: 3px 12px;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  z-index: 0;
  color: $white;
  border: 1px solid $light-grey;
  box-shadow: 0 0 20px $box-shadow;
  margin-top: 15px !important;
  &::after {
    border-bottom-color: $require-error-color !important;
  }
}


.option {
  padding: 3px 5px 3px 30px;
  background: url("../assets/images/checkbox_icon.svg") no-repeat 3%;
  font-size: 12px;
  color: $dark-grey;
}

.option:first-child {
  border-bottom: 1px solid $light-grey;
}

.option:hover{
  background-color: #EFEFFF }

.option-selected {
  background-image: url("../assets/images/checkbox_checked_icon.svg");
}

.add-input-title {
  font-size: 12px;
  color: $grey;
  margin-left: 9px;
  padding-bottom: 4px;
  text-align: left;
}

.add-input-container {
  display: flex;
  justify-content: space-between;
}

.add-input-container .input-btn{
  background-color: blue;
  border-radius: 50%;
  padding-top: 3px;
  width: 27px;
  margin-left: 6px;
  margin-bottom: 5px;
  margin-right: 6px;
}

.add-input {
  border: 1px solid $blue;
  color: black;
    background: none;
    width: 80%;
    margin-left: 9px;
    margin-bottom: 5px;
    -webkit-appearance: none;
    border-radius: 5px;
}
