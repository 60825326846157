@import '../../../../../assets/style/variables';

.modal {
  min-height: 0;
  padding: 16px 32px 32px;

  div header {
    text-align: center;
    width: fit-content;
    margin: 0 auto 10px;
  }

  input[type="file"] {
    display: none;
  }

  label[for="avatar"] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cropper-wrapper {
    width: fit-content;
    margin: 0 auto;
  }

  .add-photo-container {
    padding: 32px 0;
  }

  .edit-photo-container {
    position: relative;

    label {
      position: absolute;
      top: -10px;
      right: -10px;
      z-index: 10;
    }
  }

  .add-photo {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: $color-gray;
  }

  .btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button {
      width: 172px;
    }

    button + button {
      margin-left: 24px;
    }
  }
}