@import '../../../../assets/style/colors';
@import '../../../../assets/style/variables';

.container {
  text-align: left;
  padding-top: 24px;
}

.header {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  padding-bottom: 24px;
}

.comment-container {
  padding: 24px 0;
}

.btn-container {
  text-align: right;
}

.btn-container .btn {
  width: 200px;
}
