@import '../../../../../assets/style/colors';

.modal {
  min-height: 415px;
}
.form {
  header {
    text-align: center;
    margin-bottom: 40px;
    color: $dark-grey;
  }
}
.terminating-date,
.termination-initiator {
  width: 272px;
}

.terminating-date {
  margin-right: 23px;
  [class^="react-datepicker__input-container"] input {
    margin-top: 0;
  }
}

.comment {
  width: 100%;
    textarea {
      height: 109px!important;
    }
}

.form-row-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .div-btn-wrapper {
    width: 172px;
  }
}
