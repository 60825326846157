@import "../../assets/style/variables";

.container {
  width: $width;
  min-width: $width;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: $size-lg 0;
  background: linear-gradient(270deg, #3737ED 0%, #1F1FEB 100%);
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-sm;
  line-height: 18px;
  color: $color-white;
  z-index: 10;
  transition: width 350ms;
  margin-top: -$top-margin;
  margin-left: -190px;
  height: 100vh;

  &.collapsed {
    width: 48px;
    min-width: 48px;
    transition: width 350ms;
    margin-left: 0;
    left: 0;
  }

  .logo {
    height: $size-lg;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .side_bar_ul {
    display: flex;
    flex-direction: column;
    padding-left: 15px;

    .side_bar_li {
      position: relative;
      text-decoration: none;
      list-style-type: none;

      .side_bar_div {
        position: relative;
        display: inline-flex;
        width: $width-lg;
        padding: $size-sm 15px $size-sm 0;
        line-height: 1.4;
        color: $color-white;
        border-radius: 16px 0 0 16px;
        border: none;
        cursor: pointer;
        text-decoration: none;
        align-items: center;

        .side_bar_icon {
          margin: 0 7px;
          display: inline-block;
          position: relative;

          i {
            height: 24px;
            width: 24px;
          }

          svg path {
            position: absolute;
            width: inherit;
            height: inherit;
            fill: $color-white;
          }

          svg rect {
            stroke: $color-white;
          }
        }
      }

      .accordion_ul {
        list-style: none;
        text-decoration: none;
        margin: 10px 0 0 0;
        padding-left: 40px;

        max-height: 300px;
        height: auto;
        transition: transform .5s ease-in-out;
      }

      .hidden {
        height: 0;
        max-height: 0;
      }

      .side_bar_div::before,
      .side_bar_div::after {
        content: "";
        position: absolute;
        right: 0;

        width: 24px;
        height: 23px;
        transition: all .23ms ease-in-out;
        background: url("../../assets/images/Vector 2.svg") no-repeat;

        visibility: hidden;
        opacity: 0;
      }

      .side_bar_div::before {
        background: url("../../assets/images/Vector 1.svg") no-repeat;
      }

      .side_bar_div.active {
        pointer-events: none;
        color: $blue;
        text-decoration: none;
        background: $bg-white;
        border-radius: 16px 0 0 16px;
        border-right: none;
        position: relative;

        span {
          color: $color-black;
        }

        .side_bar_icon svg path {
          fill: $blue;
        }

        .side_bar_icon svg rect,
        .side_bar_icon svg circle {
          stroke: $blue;
        }

        transition: all 0.23ms ease-in-out;
      }

      .side_bar_div.active::before {
        opacity: 1;
        visibility: visible;
        top: -23px;
      }

      .side_bar_div.active::after {
        opacity: 1;
        visibility: visible;
        bottom: -23px;
      }
    }
  }

  .collapse {
    align-self: flex-end;
    height: 20px;
    background: none;
    border: none;
    outline: 0 !important;
    position: absolute;
    bottom: 1rem;
    padding: 0 20px 0 13px;
    transition: all 350ms;
    cursor: pointer;
  }

  .languageButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    position: relative;
    top: 17px;
    padding-left: 10px;
    left: 10px;
  }

  .languageButtonsCollapse {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .collapseCurrentLanguage {
    position: relative;
    background: #1f1feb00;
    color: white;
    font-weight: bold;
    cursor: pointer;
    padding-top: 6px;
  }

  .collapseCurrentLanguage:hover {
    text-shadow: 0px 0px 10px rgb(0, 0, 0);
  }

  .collapseLanguage {
    position: relative;
    background:  #1f1feb00;
    color: #AAAAAA;
    font-weight: normal;
    cursor: pointer;
    padding-top: 6px;
  }

  .collapseLanguage:hover {
    text-shadow: 0px 0px 10px rgb(0, 0, 0);
  }

  .languages {
    background: none;
    border: none;
    outline: 0 !important;
    position: absolute;
    bottom: 2.3rem;
    padding: 0;
    transition: all 350ms;
  }

  &.collapsed .collapse {
    padding: 0 5px 0 15px;
    transition: all 350ms;
    transform: rotate(180deg);
  }

  &.collapsed .side_bar_div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px !important;
    height: 40px !important;
    padding: 0 !important;
    transition: all 350ms;
  }

  &.collapsed .side_bar_div.active {
    background-color: #fff !important;
    border-radius: 12px !important;
    transition: all 350ms;

  }

  &.collapsed .side_bar_div::before,
  &.collapsed .side_bar_div::after {
    display: none
  }

  &.collapsed li[class].side_bar_li {
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content: center;
  }

  &.collapsed li[class].side_bar_li a[class] {
    background: none;
  }

  &.collapsed ul[class].side_bar_ul {
    padding-left: 0;
  }

  &.collapsed ul[class].accordion_ul {
    position: absolute;

    min-width: 124px;
    min-height: 80px;
    max-height: 135px;
    left: 60px;
    top: 0;
    margin-top: 0;

    background: $white;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 10px 0 10px 24px;

    transition: none;

    li {
      margin-top: 12px;
    }

    li:first-child {
      margin-top: 0;
    }

    a {
      color: $dark-grey
    }

    a::after {
      background-color: $btn--color-outline;
    }
  }

  &.collapsed ul[class].accordion_ul::before {
    content: '';
    position: absolute;
    top: 12px;
    left: -20px;
    border: 10px solid transparent;
    border-right-color: $white;

    width: 10px;
    height: 10px;
  }

  &.collapsed .hide {
    display: none !important;
  }

  .menu_top {
    flex: 1;
    margin-top: 100px;
    justify-content: flex-start;
  }
}
