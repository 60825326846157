@import '../../../../../assets/style/variables';

div[class].modal {
  width: 500px;
  height: 214px;
  padding: 32px 63px 48px 64px;
  min-height: 214px;
  text-align: center;
    p {
      font-size: $font-size-xs;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 40px;
    }
    header[class^="style_header"] {
      position: absolute;
      top: -1000px;
    }
}
header {
  font-size: $font-size-lg;
  font-weight: 700;
  margin-bottom: 8px;
}

.row {
  display: flex;
  justify-content: space-between;
    button {
      width: 172px!important;
      height: 40px;
      font-size: $font-size-sm;
      font-weight: 600;
      line-height: 24px;
    }

    button + button {
      margin-left: 24px;
    }
}
