@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.container {
  @include container;
  position: relative;
}

.header {
  @include top-header;
  h1 {
    @include top-header-h1
  }
  margin-bottom: 24px;
}

.header-btns {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.btns-container .btn,
.btns-container .btnApprove{
    margin-left: 20px;
    width: 170px;
}

.btns-container .btn {
  color: $green;
  border: 1px solid $green;
}

.btns-container .btn:hover {
  background: linear-gradient(180deg, $green-btn-gradient 0%, $green 100%);
}

.btns-container .btnApprove {
  background: linear-gradient(180deg, $green-btn-gradient 0%, $green 100%);
}

.status{
  border-radius: 20px;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: $dark-grey;
  background: $white;
  padding: 4px 12px;
}
.orange{
  background: $orange-light;
  color: $orange;
}
.green{
  background: $light-green;
  color: $green;
}

.red{
  background: $light-red;
  color: $red;
}
.green{
  background: $light-green;
  color: $green;
}
.blue{
  background: $light-blue;
  color: $blue;
}

.closed-filter {
  display: none;
}

.filter-toggle-btn-container {
  position: relative;

  .filter-toggle-btn {
    position: absolute;
    right: 19px;
    top: 11px;
    background-color: inherit;
    cursor: pointer;
  }
}

.cursor{
  cursor: pointer;
}
