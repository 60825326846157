@import '../../../../../assets/style/variables';
.tableWrapper {
  width: $width-table-wrapper;
  margin: $margin-table-wrapper;
  ::-webkit-scrollbar {
    width: $width-webkit-scrollbar;
  }

  ::-webkit-scrollbar-track {
    background: $scroll-bar-background;
    box-shadow: $shadow-webkit-scrollbar;
    border-radius: $border-radius-webkit-scrollbar;
  }

  ::-webkit-scrollbar-thumb {
    background: $scroll-bar-thumb;
    border-radius: $border-radius-webkit-scrollbar;
    width: $width-webkit-scrollbar;
    height: $height-webkit-scrollbar;
  }
}
