@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.filter-wrapper{
  width: 100%;
  border: 1px solid $light-grey;
  min-height: $size-md*4;
  background: $white;
  box-shadow: 0 0 20px $box-shadow;
  border-radius: 14px;
  display: flex;
  flex-wrap: wrap;
  padding: 0 18px 16px 32px;
  margin-bottom: 12px;

  .filter-fields{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 24px;
    margin-top: 13px;

    label{
      color: $grey;
      font-size: 12px;
      line-height: 16px;
      text-align: left;
      width: 100%;
    }
  }


  .filter-range{
    text-align: center;
    label{
      margin-bottom: 7px;
    }
    div{
      display: flex;
      flex-direction: row;
      align-items: center;

      .range-span{
        margin: 0 8px;
      }
    }
  }
  .filter-data-range{
    display: flex;
    label{
      margin-bottom: 3px;
    }
    .data-picker-row,
    .newrange-data-picker-row{
      display: flex;
      flex-direction: row;
      align-items: center;
      .data-picker-text {
        margin-right: 7px;
        padding-top: 6px;
        font-size: 12px;
        line-height: 16px;
        color: $dark-grey;
      }
      .data-picker-text-to{
        margin-left: 8px;
      }
    }
  }
  .btn-complete {
    max-width: 100px;
  }
  .btn-reset {
    margin-top: 7px;
    cursor: pointer;
  }
}


.btn-filter{
  position: relative;
}
.hidden{
  display: none;
}
.showed{
  display: flex;
}

.fields-container {
  display: flex;
  flex-wrap: wrap;
  min-width: 947px;
  width: 90%;
}
.fields-container-for-multline {
  justify-content: space-between;
}

.btns-container {
  display: flex;
  justify-content: end;
  padding-top: 36px;
  min-width: 100px;
  width: 10%;
  .icon {
    height: fit-content;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

.picker-container {
  width: 100%;
  margin-top: 3px;
}
.newrange-data-picker-row {
  border: 1px solid $blue;
  border-radius: 10px;
  margin-top: 4px;
    .data-picker-dash {
      width: 6px;
      border-top: 1px solid $dark-grey;
    }
    .datepicker-icon {
      width: 12px;
      height: 12px;
      padding-left: 9px;
      padding-right: 9px;
      background: url(../../../assets/images/calendar.svg) no-repeat;
    }
}
.newrange-data-picker-row div[class^="react-datepicker__input-container"] input{
  border: 1px solid transparent;
  padding-left: 4px;
  padding-right: 4px;
  margin: 0;
  background: unset;
  box-shadow: unset;
  text-align: center;
}

.multi-select div[class$="-control"] {
  height: unset;
}
