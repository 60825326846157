@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.container {
  position: relative;
  width: 100%;
  min-width: 1366px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  background: $white;

  .poster{
    width: 60%;
    height: inherit;
    padding: 10vw 0;
    align-items: center;
    display: flex;
    justify-content: center;

    .img{
      width: 100%;
      height: 100%;
      min-width: 674px;
      min-height: 617px;
      background: url("../../../assets/images/login.svg") no-repeat center center;
    }
  }
  .main{
    width: 40%;
    height: inherit;
    display: flex;

    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    padding: 75px 21px;

    .header {
      font-style: normal;
      font-weight: bold;
      font-size: 40px;
      line-height: 54px;
      color: $color-black;
    }
  }
}

.link {
  width: 200px;
  height: 40px;
  box-shadow: 0px 5px 15px $box-shadow-btn;
  border-radius: 14px;
  background: $btn-login-bg;
  color: $white;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  padding: 7px 15px;
  margin-top: 60px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $white;
    background: $bg-hover;
    box-shadow: 0px 5px 10px $box-shadow-btn-hover;
  }
  &:active {
    color: $white;
    background: $btn-login-bg-active;
    box-shadow: 0px 5px 15px $box-shadow-btn;
  }
}

.toggle {
  position: absolute;
  top: 5%;
}
