@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";


.actions-cell-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.discharge-btn {
  cursor: pointer
}

.container-for-hidden-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 40px;
}

.none {
  display: none;
}