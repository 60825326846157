@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import '../../../../../assets/style/mixins';

div[class].modal {
  width: 360px;
  height: 500px;
}
.container{
  width: 100%;
  text-align: center;

}
.header{
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: $dark-grey;
  margin-bottom: 5px;
}
.comment-title{
  text-align: left;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $grey;
}
.comment{
  textarea {
    height: 90px !important;
    margin-bottom: 24px;
  }
}
.btn{
  button{
    cursor: pointer;
  }
}
