@import "variables";
@import "colors";

@mixin container{
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

@mixin header {
  font-weight: bold;
  font-size: $font-size-lg;
  line-height: 27px;
  margin: 0 $size-sm $size-sm;
  text-align: left;
  color: $dark-grey;
  max-width: $global-max-width;
}

@mixin table-wrapper {
  box-sizing: border-box;
  background: $white;
  border: 1px solid $light-grey;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  overflow: hidden;
  padding: 0 40px;
  max-width: $global-max-width;

}

@mixin table-cell-td {
  text-align: left;
  width: 25%;
  font-weight: normal;
  line-height: 19px;
}

@mixin table-cell-th {
  font-weight: bold;
  text-align: left;
  cursor: pointer;
  width: 25%;
}

@mixin main-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
}

@mixin label-text {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}

@mixin progress-bar-pointer {
  content: '';
  position: absolute;
  left: calc(100% - 3px);
  top:-2px;
  display: block;
  border-radius: 50%;
  background: $blue;
  height: 8px;
  width: 8px;
}

@mixin pagination{
  margin: auto 20px;
  display: flex;
  justify-content: center;
}

@mixin pagination-item {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 24px;
  font-weight: 600;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
  border-radius: 6px;
  border: none;
  color: $dark-grey;
}

@mixin pagination-active {
  background-color: $blue;
  color: $white;
}

@mixin pagination-item-svg {
  svg path {
    stroke: $blue;
  }
}

@mixin pagination-disabled {
  svg path {
    stroke: $grey;
  }
}

@mixin top-header{
  text-align: left;
  position: relative;
}

@mixin top-header-h1{
  position: fixed;
  top: -10px;
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: $dark-grey;
  z-index: 1000;
}

@mixin grid{
  display: grid;
  grid: 1fr/ 1fr 1fr 1fr 1fr;
  grid-gap: 22px 22px;
  margin-top: 22px;
}

@mixin mini-title{
  display: inline-block;
  width: 100%;
  margin-top: 24px;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: $dark-grey;
  text-align: left;
}

@mixin profile-info-section {
  display: flex;
  flex-direction: column;
  background: $color-white;
  border: 1px solid $light-grey;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 28px 32px;
  margin-bottom: 24px;

  h2 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.01em;
    align-self: flex-start;
    margin: 0;
  }
}