@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

div[class].modal {
  width: 620px;
  height: 484px;
  min-height: 200px;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 200px;
  height: 400px;
  .form-row{
    display: flex;
    display: -webkit-box;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 22px;
    &.time-tracker {
      height: 92px;
      border-top: 1px solid #EBEBEB;
      border-bottom: 1px solid #EBEBEB;
      display: flex;
      justify-content: space-between;
    }
  }
  .form-row-header {
    justify-content: center;
    margin: 0 0 40px 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: $color-dark-blue;
  }
  .header-type{
    width: 90px;
    margin-right: 25px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: $blue;
  }
  .header-balance{
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: $dark-grey;
    width: 210px;
  }
  .header-balance-margin{
    margin-right: 30px;
    width: 210px;
    display: inline-block;
  }
  .div-wrapper{
    width: 210px;
    &.time-tracker {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    .checkbox-label {
      margin-left: 25px;
      cursor: pointer;
    }
    &.checkbox-container {
      width: fit-content;
    }
  }
  .current-balance{
    margin-right: 30px;
    &.time-tracker {
      display: flex;
      align-items: center;
    }
  }
  .form-row-btn{
    margin-top: -5px;
    justify-content: flex-end;
    margin-left: auto;
  }
  .field-label {
    color: #9A9A9A;
    font-size: 12px;
  line-height: 18px;
  }
}
