@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.container {
  @include container;
  position: relative;
  color: $dark-grey;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
}

.container-flex {
  display: flex;

  justify-content: space-between;
  padding: 24px 0 $size-sm;
  align-items: flex-start;
}
.table,
.big-icon{
  width: 50%;
}
.big-icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.green{
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: $green;
}
.center,
.blue{
  display: flex;
  margin-left: 12%;
}
.blue{
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: $btn--color-outline;
  margin-left: 18%;
}


.block-go-back{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  font-size: 12px;
  margin-top: 30px;
  position: relative;
  top: -10px
}

.go-back{
  position: static;
  width: 73px;
  height: 24px;
  left: 25px;
  top: 0px;
  background: none;
  color:$blue;
  padding: 0;
  cursor: pointer;
  svg{
    height: 18px;
    width: 18px;
    margin-bottom: -2.5px;
  }
  svg path{
    stroke: $blue;
  }
  .go-back-text{
    margin-left: 8px;
    font-size: 16px;
  }
}
