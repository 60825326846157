@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.faq-wrapper{
  @include container;
}
.width-test{
  width: 120px;
}

.header {
  margin-left: 20px;
  @include top-header;
  h1 {
    @include top-header-h1
  }
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.responsesBlock {
  position: relative;
  left: 23px;
  top: 50px;
  width: 90%;
  height: 480px;
}


.toggle {
  position: absolute;
  height: 60px;
  left: 54px;
  top: -25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.responses {
  position: relative;
  border-radius: 14px;
  margin-right: 30px;
  height: 480px;
  min-width: 700px;
  width: 67%;
  display: block;
  left: 53px;
  top: 54px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
} 

.adminResponses {
  position: relative;
  border-radius: 14px;
  height: 480px;
  min-width: 800px;
  width: 100%;
  display: block;
  left: 53px;
  top: 20px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  overflow-y: scroll;
} 

.responsesDisplay {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.icon {
  position: relative;
  left: 55px;
  top: 65px;
}

.none {
  display: none;
}

.addButton {
  position: relative;
  top: -10px;
  left: 47.1%;
  min-width: 170px;
}

.backgroundMask {
  position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(115,113,111,.4);
    z-index: 10200;
    -webkit-transition: all 1s;
    transition: all 1s;
    cursor: pointer;
    display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  width: 616px;
  height: 399px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
}

.popupSmallText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #9A9A9A;
  margin: 1px 0px;
}

.popupSmallInput {
  width: 90%;
  padding: 20px 10px;
  height: 30px;
  border: 1px solid #3737ED;
  box-sizing: border-box;
  border-radius: 10px;
}

.popupRedSmallInput {
  width: 90%;
  padding: 20px 10px;
  height: 30px;
  border: 1px solid red;
  box-sizing: border-box;
  border-radius: 10px;
}

.popupBigInput {
  width: 90%;
  resize: none;
  -moz-appearance:none;
  font-family: Arial, Helvetica, sans-serif;
  outline:0px none transparent;
  overflow: -moz-scrollbars-none;
  padding: 5px 10px 5px 10px;
  height: 109px;
  border: 1px solid #3737ED;
  box-sizing: border-box;
  border-radius: 10px;
}

.popupRedBigInput {
  width: 90%;
  resize: none;
  -moz-appearance:none;
  font-family: Arial, Helvetica, sans-serif;
  outline:0px none transparent;
  overflow: -moz-scrollbars-none;
  padding: 5px 10px 5px 10px;
  height: 109px;
  border: 1px solid red;
  box-sizing: border-box;
  border-radius: 10px;
}


.popupBigInput::-webkit-scrollbar { width: 0 !important }

.popupSubmit {
  background: linear-gradient(180deg, #1F1FEB 0%, #3737ED 100%);
  box-shadow: 0px 5px 15px rgba(31, 31, 235, 0.25);
  font-weight: 600;
  width: 170px;
  height: 40px;
  color: #FFFFFF;
  border: none;
  border-radius: 14px;
  margin-top: 24px;
  position: relative;
  left: 30%;
} 

.popupCloseBtnDiv {
  position: relative;
  display: flex;
  justify-content: flex-end;
  top: 10px;
  width: 98%;
}

form {
  p {
    position: relative;
    left: -255px;
    padding-top: 20px;
  }
}

.colorRed {
  color: red;
}