@import '../../assets/style/colors';
@import '../../assets/style/variables';

.chart {
  position: relative;
  min-width: 90px;
  height: 90px;
}

.child {
  position: absolute;
  top: 34px;
  width: 86px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
