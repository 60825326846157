@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import '../../../assets/style/mixins';

.container {
  @include container;
  position: relative;
  color: $dark-grey;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
}

.basket-element {
  display: flex;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  .basket-name-category {
    margin: 0 20px;
  }

  div:last-child {
    display: initial;

    div:first-child {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      text-align: initial;
    }

    div:last-child {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $color-gray;
    }
  }
}

.basket-hidden {
  display: none;
}

.basket-close {
  cursor: pointer;
}

.counter-element {
  height: 100px;
  display: flex;
  padding: 16px 0;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  color: $dark-grey;
  border-bottom: 1px solid $light-grey;
  .picture {
    width: 56px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    img {
      width: 56px;
      height: 56px;
      border-radius: 50%;
    }
  }
  .title-and-count{
    width: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    div:first-child{
      text-align: left;
      line-height: 18px;
    }
  }
  .cost{
    margin-top: 0;
    width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    div:first-child{
      button {
        margin: 0;
        padding: 0;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        border: none;
        background: none;
        outline: none;

        svg {
          margin: 0;
          padding: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    div:last-child{
      display: flex;
      align-items: center;
      line-height: 18px;
      span {
        display: block;
        margin-right: 4px;
      }
      i {
        display: block;
        width: 15px;
        margin: 0;
        svg {
          width: $size-md;
          height: $size-md;
          rect{
            stroke: $dark-grey;
          }
          path{
            fill: $dark-grey;
          }
        }
      }
    }
  }
}
.counter-container-basket {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-sm;
  line-height: 19px;
  text-align: center;
  color: $color-black;
  margin: 5px 0;

  button {
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;

    svg {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }

  button:disabled svg rect {
    fill: $color-gray;
  }

  button:disabled svg circle {
    stroke: $color-gray;
  }
}

.container-checkout {
  box-sizing: border-box;
  min-width: 340px;
  background: $white;
  border: 1px solid $light-grey;
  box-shadow: 0 0 $size-md rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  overflow: hidden;
  background: $white;
  padding: 32px;
  height: 528px;
  margin-left: $size-md;

  .quantity-container {
    justify-content: space-between;
    font-weight: bold;
    line-height: 26px;
    margin-top: $size-md;
    display: flex;

    .text {
      color: $color-dark-blue;
    }

    .number {
      color: $blue;
      font-size: 18px;
    }
  }

  .price-container {
    justify-content: space-between;
    font-weight: bold;
    line-height: 26px;
    margin-top: 16px;
    display: flex;

    .text {
      color: $color-dark-blue;
    }

    .total-price-container {
      text-align: right;
      display: flex;
      line-height: 22px;
      margin-top: 2px;
      .number {
        color: $blue;
        font-size: 16px;
        margin-right: 5px;
      }
      i{
        display: block;
        width: $size-md;
        height: $size-md;
        svg {
          width: $size-md;
          height: $size-md;
          rect{
            stroke: $blue;
          }
          path{
            fill: $blue;
          }
        }
      }
    }
  }

  .checkout-comment-title {
    line-height: $font-size-md;
    font-size: $font-size-xs;
    color: $grey;
    text-align: left;
    margin-top: $size-md;
  }
  .checkout-comment{
    textarea {
      height: 66px !important;
    }
  }
  .btn-container {
    margin: $size-md $size-md 0 ;
    button{ cursor: pointer}
  }
}
.container-flex {
  display: flex;

  justify-content: space-between;
  padding: $size-sm 0 $size-sm;
  align-items: flex-start;
}

.basket-deleted-all{
  display: flex;
  align-items: center;

  color: $red;
  svg{
    circle{
      fill: $red;
    }
    rect{
      fill: $white;
    }
  }
  span{
    margin-left: 10px;
  }
}

.block-go-back{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 20px;
  position: relative;
  top: 10px
}

.go-back{
  position: static;
  width: 73px;
  height: 25px;
  left: 24px;
  top: 0px;
  background: none;
  color:$blue;
  padding: 0;
  cursor: pointer;
  svg{
    height: 18px;
    width: 18px;
    margin-bottom: -2.5px;
  }
  svg path{
    stroke: $blue;
  }
  .go-back-text{
    margin-left: 8px;
    font-size: 16px;
  }
}
