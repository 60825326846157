@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.container {
  @include container;
}

.header {
  @include top-header;
  h1 {
    @include top-header-h1
  }
  margin-bottom: 24px;
}

.header-btns {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 16px;
}

.header-btns .add-btn {
  width: 170px;
}

.closed-filter {
  display: none;
}

.filter-toggle-btn-container {
position: relative;

  .filter-toggle-btn {
  position: absolute;
    right: 19px;
    top: 11px;
    background-color: inherit;
    cursor: pointer;
  }
}

footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px 0;
}

.td-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.td-cell-id {
  font-size: 12px;
  color: $grey;
}

.td-cell-people {
  width: 100%;
  text-align: center;
}
