@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.btn {
  border: none;
  background-color: inherit;
  outline: 0 !important;
}
.invalid-border {
border-color:  #D12A6C!important;
}

.tooltip{
  background-color: $require-error-color !important;
  border-radius: 6px;
  padding: 3px 12px;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  z-index: 0;
  color: $white;
  border: 1px solid $light-grey;
  box-shadow: 0 0 20px $box-shadow;
  margin-top: 15px !important;
  &::after {
    border-bottom-color: $require-error-color !important;
  }
}
