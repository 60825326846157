@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

div[class].modal {
  width: 620px;
  height: 400px;
  min-height: 200px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 200px;
  height: 400px;
  .form-row{
    display: flex;
    display: -webkit-box;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 18px;
  }
  .form-row-header{
    justify-content: center;
    margin: 0 0 25px 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;

    color: $color-dark-blue;
  }
  .form-row-btn{
    margin-top: 10px;
    justify-content: flex-end;
    margin-left: auto;
  }
  .label-for-field{
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $color-gray;
  }
}
.warning {
  width: 100%;
  padding-left: 5px;
  span {
    color: red;
  }

  text-align: left;
  font-size: 12px;
}
.left{
  width: 270px;
  margin-right: 24px;
}
.right{
  width: 260px;
}
.btn{
  width: 210px;
}
.workHour{ width: 100px; }
.workDay{ width: 130px; margin-right: 24px;}
