@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.container {
  @include container;
  position: relative;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
  .toggle{
    position: fixed;
    top: 0px;
    right: 140px;
    z-index: 1000;
  }

  .content{
    margin-top: 24px;
  }
  .points-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .points-input-container {
    display: flex;
    justify-content: space-between;
    width: 100%;

    span {
      padding: 10px 10px 0 0;
    }
  }

  footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    button {
      height: 30px;
      width: 170px;
      font-size: $font-size-xs;
      outline: none;

      span{
        margin-right: 10px;
      }
    }

    button:last-child {
      margin-left: 20px;
    }

  }
}
