@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.mini-title{
  @include mini-title;
  margin: 0;
}
.toggle{
  position: relative;
  text-align: left;
  margin-bottom: 18px;

  .points-balance {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    
    span {
      color: $green;
    }
  }
}
