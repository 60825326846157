@import '../../assets/style/colors';
@import "../../assets/style/variables";

$transition-base: all 265ms;

.togglebar {
  height: 32px;
  margin-top: 21px;
  position: relative;

  div {
    display: inline-block;
  }

  & input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  & label {
    height: 30px;
    padding: 0 10px;
    display: inline-flex;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    margin: 0;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    transition: all 0.1s;
    position: relative;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-size: 16px;
    line-height: 30px;
    color: $grey;
    background: inherit;
  }

  & label:hover {
    color: $blue;
    transition: $transition-base;
  }

  & label:active {
    color: $white;
    transition: $transition-base;
  }

  & input[type="radio"]:checked + label,
  & input[type="radio"]:focus + label {
    background: transparent;
    border-color: $blue;
    color: $blue;
    transition: $transition-base;
  }

  &.cell-toolbar {
    height: 50px;
  }

  &.cell-toolbar label {
    padding: 0 25px;
  }

  &.cell-toolbar::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    background: $blue;
  }

  &.cell-toolbar > div {
    position: relative;
  }

  &.cell-toolbar label {
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
  }

  &.cell-toolbar input[type="radio"]:checked ~ .cover,
  &.cell-toolbar input[type="radio"]:focus ~ .cover {
    position: absolute;
    bottom: -2px;
    left: -5px;
    background: $scroll-bar-background;
    width: calc(100% + 10px);
    height: 10px;
    z-index: 2;
  }

  &.cell-toolbar input[type="radio"]:checked ~ .cover::before,
  &.cell-toolbar input[type="radio"]:focus ~ .cover::before {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 2px;
    left: -5px;
    background: $scroll-bar-background;
    border-right: 2px solid $blue;
    border-bottom: 2px solid $blue;
    border-radius: 8px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.cell-toolbar input[type="radio"]:checked ~ .cover::after,
  &.cell-toolbar input[type="radio"]:focus ~ .cover::after {
    content: '';
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: 2px;
    right: -5px;
    background: $scroll-bar-background;
    border-left: 2px solid $blue;
    border-bottom: 2px solid $blue;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.cell-toolbar input[type="radio"]:checked + label,
  &.cell-toolbar input[type="radio"]:focus + label {
    border: 2px solid $blue;
    border-bottom: none;
    border-radius: 8px;
  }

  &.cell-toolbar > div:first-of-type label {
    border-left: none !important;
    border-top-left-radius: 0 !important;
  }

  &.cell-toolbar > div:first-of-type .cover::before {
    display: none !important;
  }
}