@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.container {
 @include container;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .filter {
    margin-top: 32px;
  }

  .items-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    & > div {
      margin-left: -22px;
      margin-top: 37px;
      width: calc(100% + 22px);
      max-width: 1122px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .pagination-container {
    display: flex;
    justify-content: center
  }
}
.pagination {
  @include pagination;
}

.pagination-item {
  @include pagination-item;
}

.pagination-active {
  @include pagination-active
}

.pagination-item {
  @include pagination-item-svg;
}

.pagination-disabled {
  @include pagination-disabled
}

.team-title {
  padding: 4px 10px;
  margin-bottom: 18px;
  width:fit-content;
  text-align: left;
  border-bottom: 2px solid $blue;
  color: $blue;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 1%;
}
