@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import '../../../assets/style/mixins';

.container {
  @include container;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .content-container {
    display: flex;
    justify-content: space-between;
  }

  .form-container {
    width: 46.8%;
    text-align: left;
    color: $grey;
    font-size: 12px;
    padding-top: 20px;

    .form-row-flex-container {
      display: flex;
      justify-content: space-between;
      padding: 0 0 25px;

      .form-item-name {
        width: 47%;
        label {
          width: 100%;
        }

      }

      .form-item-type,
      .form-item-date-picker {
        width: 47%;
      }
    }

    .form-item-date-picker {
      display: inline-block;
      text-align: left;
      color: $grey;
      font-size: 12px;

      span {
        display: block;
        width: 100%;
        .red-note {

          display: inline;

        }
      }

      input {
        width: 100%;
      }
    }
  }

  .details {
    width: 45%;
    text-align: left;

    .details-title {
      display: flex;
      justify-content: space-between;
      font-weight: bold;
      padding-bottom: 20px;

      .balance {
        color: $green;
      }

      .balance-red {
        color: $require-error-color;
      }
    }

    .details-title:last-child {
      padding: 20px 0 0;
    }

    .details-list-container {
      border-radius: 14px;
      overflow: hidden;
      border: 1px solid $light-grey;

      .details-list, .details-total {
        height: 350px;
        overflow-y: auto;
        box-sizing: border-box;
        width: 100%;
        background: $white;
        border: 1px $light-grey;
        padding: 18px 15px 10px;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      .details-list {
        ul {
          margin-bottom: 0;
        }
      }

      .details-total {
        display: flex;
        justify-content: space-between;
        height: 60px;
        font-weight: bold;

        .details-total-count {
          span {
            color: $green;
            margin: 0 10px;
          }
        }
      }
    }
  }

  .footer-btn-container {
    text-align: right;
    padding: 16px 0;
  }

  .red-note {
    padding-left: 5px;
    display: inline;
    color: $invalid-red
  }
  .balance-title{
    margin: 0 10px 0 0;
  }
  .balance {
    color: $green;
  }
}

.dateInvalid {
  border: 1px solid red !important;
}

.list-days {
  margin: 10px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.start-week,
.week,
.end-week {
  position: relative;
  padding-left: 15px;
}

.start-week::before,
.week::before,
.end-week::before {
  content: '';
  position: absolute;
  height: 50px;
  width: 1px;
  background-color: #DBDBFF;
  left: 0;
  top: -10px;
}

.start-week::before,
.firstDay::before,
.end-week::before {
  content: '';
  position: absolute;
  height: 30px;
  width: 1px;
  background-color: #DBDBFF;
  left: 0;
  top: 0px;
}

.start-week::before {
  height: 40px;
  top: 0px;
}

.end-week::before {
  height: 35px;
  top: -8px;
}

.container-spinner {
  padding-left: 78px;
}
.block-go-back{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  font-size: 12px;
  margin-top: 15px;
  margin-bottom: 20px;
}
.go-back{
  background: none;
  color:$blue;
  padding: 0;
  cursor: pointer;
  svg{
    margin-bottom: -2.5px;
  }
  svg path{
    stroke: $blue;
  }
  .go-back-text{
    margin-left: 8px;
    font-size: 14px;
  }
}
