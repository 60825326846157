@import '../../../assets/style/colors';
@import '../../../assets/style/variables';
@import '../../../assets/style/mixins';

.container {
 @include container;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
  h2 {
    color: $dark-grey;
  }

  .main {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
    min-width: 1120px;
    background: $color-white;
    border: 1px solid $light-grey;
    box-sizing: border-box;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    border-radius: 14px;

    .information {
      min-width: 510px;
      max-width: 550px;
      padding: 15px 10px 19px 32px;
      display: flex;
      font-size: 12px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        display: block;
        height: 176px;
        width: 1px;
        background: #EBEBEB;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .personal-info {
        img {
          width: 130px;
          height: 130px;
          background-blend-mode: normal;
          box-shadow: 0 0 20px rgba(179, 179, 248, 0.2);
          border-radius: 50%;
          margin: 13px $size-md*2 24px 0;
        }
        .socials {
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          margin-right: $size-md*2;
          margin-bottom: 11px;
        }
      }

      .description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
        .description-info{
          margin-bottom: 11px;
          color: $dark-grey;
          span {
            display: block;
            width: 100%;
          }
          .description-name{
            display: inline-block;
            font-weight: bold;
            font-size: 30px;
            line-height: 25px;
            margin-bottom: 8px;
            margin-top: 33px;
            line-height: 41px;
          }
          .description-position{
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 20px;
          }
        }
      }
    }

    .main-info {
      display: flex;
      padding: 48px 28px;
      width: 340px;
      flex-direction: column;
      justify-content: space-between;
      .main-info-item {
        display: flex;
        overflow: hidden;
        height: 24px;
        align-items: center;
        .main-info-logo {
          width: 15px;
        }
        span {
          display: block;
          font-size: 14px;
          line-height: 24px;  
          margin-left: 8px;
        }
      }
    }

    .mood-scale {
      display: flex;
      flex-direction: column;
      width: 361px;
      h2 {
        font-size: 16px;
        line-height: 26px;
        margin-top: 0;
        margin-bottom: 18px;
        width: fit-content;
      }
      .row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 21px;
        margin-right: 25px;
      }
      .mood-scale-cap {
        border: 1px solid $color-light-grey;
        min-width: 240px;
        max-width: 85%;
        height: 139px;
        border-radius: 6px;
        background: url("../../../assets/images/mood-scale-cap.png") no-repeat center center;
      }
    }

    .points {
      display: flex;
      width: 40%;
      margin-left: 20px;
      background: $color-white;
      border: 1px solid $light-grey;
      box-sizing: border-box;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
      border-radius: 14px;
      padding: 24px;
      min-width: 550px;
      .points-icon{
        margin-right: 15px;
      }

      .points-value-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .points-value {
          font-weight: bold;
          font-size: $font-size-sm*3;
          line-height: 57px;
          text-align: center;
        }
      }

      img {
        border-radius: 50%;
      }
    }

    .send-btn {
      height: 48px;
      background: linear-gradient(180deg, #1F1FEB 0%, #3737ED 100%);
      box-shadow: 0 5px 15px rgba(31, 31, 235, 0.25);
      border-radius: 14px;
      font-weight: 600;
      font-size: $font-size-md;
      line-height: 22px;
      text-align: center;
      color: $white;
      outline: none;
      border-style: none;
    }

    .send-btn:hover {
      background: $blue;
      box-shadow: 0 5px 10px rgba(55, 55, 237, 0.4);
      transition: color 0.2s;
    }

    .send-btn:active {
      background: linear-gradient(180deg, #101098 0%, #1F1FEB 100%);
      box-shadow: 0 0 10px rgba(16, 16, 152, 0.4);
    }

    .rating-btn {
      height: 48px;
      background: $color-white;
      border: 1px solid $blue;
      box-sizing: border-box;
      box-shadow: 0 5px 15px rgba(179, 179, 248, 0.15);
      border-radius: 14px;
      font-weight: 600;
      font-size: $font-size-md;
      line-height: 22px;
      text-align: center;
      color: $blue;
      outline: none;
    }

    .rating-btn:hover {
      background: $blue;
      box-shadow: 0 5px 10px rgba(55, 55, 237, 0.4);
      color: $white;
      transition: color 0.2s;
    }

    .rating-btn:active {
      background: linear-gradient(180deg, #101098 0%, #1F1FEB 100%);
      box-shadow: 0 0 10px rgba(16, 16, 152, 0.4);
      color: $white;

    }
  }

  .main-toggle-bar-wrapper {
    display: flex;
    align-items: flex-end;
    margin-top: 27px;
    margin-bottom: 32px;
    text-align: left;
    justify-content: space-between;
    width: auto;
    & > div {
      width: fit-content;
      margin: 0;
    }
    .points-btns{
          display: flex;
          align-items: center;
          .send-friend{
            font-size: 14px;
            width: 160px;
            margin-left: 17px;
          }
          .rating{
            width: 160px;
            font-weight: 600;
            font-size: 14px;
          }
        }
  }

  .block_transaction{
    header{
      font-size: $font-size-sm;
      line-height: 19px;
      margin: 0 0 $size-sm 0;
    }

    .container-spinner{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      height: 300px;
    }
  }

  .footer-btn-container {
    text-align: right;
    padding: 16px 0;

    button {
      width: 210px;
    }
  }

}
.dashboard {
  display: flex;
  justify-content: space-between;
}
.working-container{
  width: 100%;
  text-align: center;
  margin: 0;
  max-height: 500px;
  .working-image{
      width: 100%;
      height: 400px;
      max-height: 500px;
      background: url("../../../assets/images/working_go_it.svg") no-repeat center center;
  }
}

.career-achivements-mock-graph {
  border: 1px solid $color-light-grey;
  border-radius: 14px;
}

