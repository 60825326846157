@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.modal {
  width: 616px;
  height: 400px;
  text-align: center;
  color: $dark-grey;

  header {
    padding-bottom: 10px;
  }
}
.requests-container {
  max-height: 400px;
  overflow-y: auto;
  width: 100%;
}

.item-container {
  padding: 35px 20px 35px 0;
  border-bottom: 1px solid $light-grey;
  font-size: 14px;
}

.item-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  text-align: left;
}

.item-name {
  font-weight: 600;
}

.item-id {
  font-size: 12px;
  color: $grey;
}

.item-title {
  color: $blue;
}

.btns-container .btn{
  margin-left: 16px;
  width: 120px;
}

.status{
  border-radius: 20px;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: $dark-grey;
  background: $white;
  padding: 4px 12px;
}
.orange{
  background: $orange-light;
  color: $orange;
}
.red{
  background: $light-red;
  color: $red;
}
.green{
  background: $light-green;
  color: $green;
}
.blue{
  background: $light-blue;
  color: $blue;
}
.status-container {
  width: 120px;
}