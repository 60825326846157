@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import '../../../assets/style/mixins';

.container {
  width: 100%;
  padding: 0 20px;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .section_filter {
    margin-top: $size-lg;
  }

  .main {
    @include grid;
  }
}

.pagination {
  @include pagination;
}

.pagination-item {
  @include pagination-item;
}

.pagination-active {
  @include pagination-active
}

.pagination-item {
  @include pagination-item-svg;
}

.pagination-disabled {
  @include pagination-disabled
}
