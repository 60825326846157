@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import '../../../assets/style/mixins';

.container {
  @include container;
  position: relative;
  color: $dark-grey;
  font-weight: normal;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
  .toggle-container{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    margin-top: 26px;
    margin-bottom: 16px;
  }
  .toggle{
    position: absolute;
    top: -15px;
    left: 0;
  }
  .btn-added{
    width: 170px;
    cursor: pointer;
  }
  .btn-filter{
    position: relative;
  }
  .hidden{
    display: none;
  }
  .showed{
    display: block;
  }
  .title{
    font-size: 14px;
    line-height: 24px;
  }
  .id{
    color: $grey;
    font-size: 12px;
    line-height: 18px;
  }
}
