@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';

.wrapper-checkbox{
  height: 100%;
  display: block;
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  line-height: 19px;
  color: $dark-grey;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    width: 16px;
    height: 16px;
    background-color: $white;
    border: 1px solid $blue;
    border-radius: 3px;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  .checkmark-header {
    top: -1px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

  }

}

input:checked ~ .checkmark:after {
  display: block;
}

.wrapper-checkbox .checkmark:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid $blue;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.wrapper-checkbox-disabled{
  .checkmark{
    border: 1px solid $btn-color-disabled;
    cursor: not-allowed;
  }
}
