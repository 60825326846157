@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.container {
  @include container;
  position: relative;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
  .toggle-bar{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
  }
  .content{
    margin-top: 24px;
  }

  .filter-selects-container {
    display: flex;
    justify-content: left;

    .filter-select-author-container {
      width: 180px;
      padding-right: 20px;
    }
    .filter-select-type-container,
    .filter-select-status-container {
      width: 120px;
      padding-right: 20px;
    }

    .filter-select-date-container {
      width: 255px;
      padding-right: 20px;
    }
  }

  .footer-btn-container {
    display: flex;
    justify-content: space-between;
    padding: 16px 0;

    button {
      width: 210px;
      height: 40px;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;

      svg {
        margin-left: 7px;
        width: $size-sm;
        height: $size-sm;
      }
    }

    .btn-managers{
      button {
        margin-left: 20px;
      }
    }
  }

  .btn-container-if-user {
    display: block;
    text-align: right;
  }
  .hide-if-user {
    display: none;
  }
}

.container-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}
div[class].modal-details{
  width: 430px;
  min-height: 300px;
  max-height: 500px;
}
.details-container{
  width: 100%;
  position: relative;
  header {
    @include header;
    text-align: center;
    margin-bottom: 25px;
  }
  .details-info{
    //border: 1px solid red;
    //padding-right: 8px;
    .details-info-row{
      width: 360px;
      display: flex;
      justify-content:  space-between;
      align-items: flex-end;
      margin-bottom:20px;
      margin-right: 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: $dark-grey;

      .details-info-title,
      .details-info-context{
        width: 51%;
        //border: 1px solid green;

        &:last-child {
          width: 160px;
        }
      }
      .details-info-title{
        font-weight: bold;
      }
      .details-info-context-green{
        color:$green;
        font-weight: bold;
      }
    }
    .details-info-row:last-child{
      margin-bottom: 0;

      flex-direction: column;
      .details-info-title,
      .details-info-context{
        width: 100%;
      }
      .details-info-context{
        max-height: 80px;
        overflow-y: auto;
        width: 100%;
        word-break: break-all;
      }
    }
  }
}
.cursor{
  cursor: pointer;
}
