@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.container {
  @include container;
  padding-top: 30px;
  position: relative;
  color: $dark-grey;
  font-size: 14px;
}

.header {
  @include header;
  text-align: left;
  position: relative;

  h1 {
    position: absolute;
    top: -95px;
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    margin: 0;
  }
}

.details {
  position: relative;
  top: 10px;
  display: flex;
  justify-content: space-between;
}

.details-image {
  position: relative;
  width: 47%;
  min-width: 550px;
  height: 366px;
  border-radius: 14px;
  overflow: hidden;
  img {
    height: auto;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.price {
  display: flex;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  min-width: 78px;
  height: 32px;
  background-color: $blue;
  font-style: normal;
  font-weight: bold;
  font-size: $font-size-sm;
  line-height: 24px;
  color: $white;
  border-radius: 0 12px 0 14px;
  z-index: 2;
  .logo {
    padding-top: 3px;
    float: right;
  }
  .logo path {
    fill: $white;
  }
}

.people {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  min-width: 53px;
  height: 32px;
  background: $blue;
  mix-blend-mode: normal;
  border-radius: 12px 0 14px 0;
  color: $white;
  font-weight: bold;
  font-size: $font-size-sm;
  line-height: 24px;
  z-index: 2;

  .people_icon {
    margin-left: 8px;
    padding-top: 3px;
    svg {
      width: 13px;
      height: 15px;
      path {
        fill: $white;
      }
    }
  }
}

.details-description {
  display: flex;
  flex-direction: column;
  width: 45%;
  text-align: left;
  h3 {
    font-size: 30px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 24px;
  }
  span {
    color: $blue;
    font-weight: bold;
    margin-right: 10px;
  }
}

.details-description-item{
  margin-bottom: 48px;
}

.details-description-text {
  display: inline-block;
}

.details-btn-container {
  display: flex;
  justify-content: left;
  margin-top: auto;
}

.details-btn-container .basket-btn {
  width: 200px;
  font-size: 14px;
}

.basket-counter-wrapper {
  display: flex;
  justify-content: space-between;
  width: 85px;
  margin-right: 40px;
  button {
    background-color: inherit;
  }
}

.basket-counter {
  font-weight: bold;
  font-size: 20px;
  padding-top: 7px;
}

.input-basket-counter-wrapper {
  display: none;
}


.block-go-back{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 20px;
  position: relative;
  top: -10px
}

.go-back{
  position: static;
  width: 73px;
  height: 24px;
  left: 25px;
  top: 0px;
  background: none;
  color:$blue;
  padding: 0;
  cursor: pointer;
  svg{
    height: 18px;
    width: 18px;
    margin-bottom: -2.5px;
  }
  svg path{
    stroke: $blue;
  }
  .go-back-text{
    margin-left: 8px;
    font-size: 16px;
  }
}