.TeamItemRow {
  cursor: pointer;
  width: fit-content!important;
  div [class*="bodyTdText"] {
    width: 100%;
  }
}

div [class*="Table_headerTrTeam"] {
  min-width: 100%;
  width: fit-content;
}
div [class*="Table_bodyTrTeam"] {
  min-width: 100%!important;
  width: fit-content;
}

.formatCell {
  text-align: center!important;
}
