@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.container {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: $dark-grey;
  padding-top: 6px;
}

.add {
  min-width: 210px;
}

div[class].modal {
  width: 616px;
  min-height: 400px;
}
