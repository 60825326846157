@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.input-label {
  font-size: 12px;
  color: $grey;
  padding-bottom: 4px;
  padding-left: 5px;
}

.div-wrapper{
  display: flex;
  justify-content: space-between;
  width: 177px;

  button {
    background-color: inherit;
    outline: 0 !important;
  }
}

.input-wrapper {
  margin: 0 10px 0;
}

.red-note {
  padding-left: 5px;
  display: inline;
  color: $invalid-red
}

.balance-info {
  color: $dark-grey;
  font-weight: bold;
  font-size: 14px;
}

.current-balance{
  margin-left: 10px;
  color: $green;
  font-size: 18px;
}

.points-edit-comments{
  width: 100%;
  textarea {
    height: 98px;
  }
}
