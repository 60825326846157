@import '../../../../assets/style/colors';
@import "../../../../assets/style/variables";

.container {
  overflow: hidden;
}
.reviewStars-input input:checked ~ label,
.reviewStars-input label,
.reviewStars-input label:hover,
.reviewStars-input label:hover ~ label {
  background: url("../../../../assets/images/icon_star_passive_big.svg") no-repeat;
}

.reviewStars-input {
  overflow: hidden;
  *zoom: 1;
  position: relative;
  float: left;
}

.reviewStars-input input {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  width: 40px;
  height: 24px;
  position: absolute;
  top: 0;
  z-index: 0;
}

.reviewStars-input input:checked ~ label {
  background: url("../../../../assets/images/icon_star_active_big.svg") no-repeat;
  height: 24px;
  width: 40px;
}

.reviewStars-input label {
  background-position: 0 0;
  height: 24px;
  width: 40px;
  float: right;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
  z-index: 1;
}

.reviewStars-input label:hover,
.reviewStars-input label:hover ~ label {
  background: url("../../../../assets/images/icon_star_active_big.svg") no-repeat;
  height: 24px;
  width: 40px;
}

.reviewStars-input #star-1 {
  left: 0px;
}
.reviewStars-input #star-2 {
  left: 53px;
}
.reviewStars-input #star-3 {
  left: 106px;
}
.reviewStars-input #star-4 {
  left: 159px;
}
.reviewStars-input #star-5 {
  left: 212px;
}
