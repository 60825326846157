.item {
  display: flex;
  justify-content: left;
  border-bottom: 1px solid #EBEBEB;
}

.openedItem {
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  min-height: 60px;
}



.openedItemQuestion {
  font-weight: 700;
  margin-left: 20px;
  margin-right: 20px;
  min-height: 73px;
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.adminOpenedItemQuestion {
  font-weight: 700;
  margin-left: 30px;
  margin-right: 20px;
  min-height: 73px;
  display: flex;
  justify-content: left;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.none {
  display: none;
}

.icon {
  position: absolute;
  left: 94%;
}

.adminIcon {
  position: absolute;
  left: 15px;
}

button {
  background-color: #ffffff;
}

.adminIcons {
  position: absolute;
  left: 92%
}

.responce {
  position: relative;
  left: 30px;
  margin-right: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}