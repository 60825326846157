@import '../../../../../assets/style/colors';

.container {
  position: relative;
  left: -7px;
  text-align: center;
  width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  z-index: 7;
}

.openSetColumnsWrapper {
  width: 400px;
  position: absolute;
  left: 188px;
  transform: translate(-50%, -25%);
  z-index: 8;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: $white;
  padding-top: 7px;
  padding-bottom: 14px;
  column-count: 2;
}

.checkbox-label {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: start;
}

.icon-container {
  cursor: pointer;
}

.checkbox-container {
  height: 28px;
  padding: 6px 0 6px 16px;
    &:hover {
      background-color: $tableHover;
    }
}
