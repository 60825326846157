@import '../../../../../assets/style/colors';

.modal {
  width: 500px;
  min-height: 214px;

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $dark-grey;
    text-align: center;
    margin: 0;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $dark-grey;
    text-align: center;
    margin-bottom: 20px;
  }

  .row {
    display: flex;
    justify-content: center;
  }

  .save-btn,
  .cancel-btn {
    width: 172px;
    cursor: pointer;
      &:disabled {
      cursor: not-allowed;
    }
  }

  .cancel-btn {
    margin-right: 24px;
  }
}