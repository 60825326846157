@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.header {
  @include header();
  text-align: center;
}

.container {
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: space-between;
  //align-items: center;
  .type {
    width: 25%;
    //padding-top: 17px;
  }
  .description {
    width: 45%;
    padding-top: 7px;
  }
  .date {
    width: 25%;
    padding-top: 3px;
  }
}

.input-label {
  font-size: 12px;
  color: $grey;
  text-align: left;
  width: 25%;
}

.input-label:nth-child(2) {
  width: 45%;
}

.buttons {
  padding-top: 80px;
  margin-top: 40px;
  text-align: right;
}
.buttons > .save{
  width: 210px;

}
