@import '../../../../../assets/style/colors';
.container{
  position: relative;
  width: 100%;
  margin-left: 150%;
  text-align: center;
  padding-right: 15px;
  i{
    cursor: pointer;
  }
}
.cell-container {
  position: relative;
  text-align: center;
  width: 100%;
}

.wrapper {
  font-size: 12px;
  position: absolute;
  z-index: 20;
  top: -8px;
  right: 7px;
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: $white;
  border-radius: 6px;
  border: 1px solid $light-grey;
  box-shadow: 0 4px 10px rgba(51, 51, 51, 0.1);
  width: 220px;
}
.comment{
  max-height: 100px;
  min-width: 180px;
  overflow-y: hidden;
  padding-right: 5px;
  text-align: left;
}

.commentScroll{
  max-height: 100px;
  min-width: 180px;
  overflow-y: scroll;
  padding-right: 5px;
  text-align: left;
}

.btn-container {
  min-width: 20px;
  display: flex;
  align-items: flex-start;

  button {
    padding-top: 7px;
    position: absolute;
    top: 0;
    background-color: inherit;
    cursor: pointer;
  }
}
