@import '../../../assets/style/colors';
@import "../../../assets/style/variables";
@import "../../../assets/style/mixins";

.containerTeamAdmin {
  @include container;
    [class*="headerTh"] {
      position: relative
    }
}

.header {
  @include top-header;
  h1 {
    @include top-header-h1
  }
  margin-bottom: 24px;
}

.header-history {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 16px;

  .container-change-history {
    display: flex;
    align-items: center;
  }

  .change-history {
    margin-left: 6px;
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $blue;
    padding-top: 3px;
  }
}
.row {
  cursor: pointer;
}

.active-users {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: $dark-grey;
  text-align: left;
  margin-top: 25px;
  .active-number-users {
    margin-left: 5px;
    color: $approve-green;
  }
  .inactive-number-users {
    margin-left: 5px;
    color: $red;
  }
}

