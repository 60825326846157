@import '../../assets/style/colors';
@import "../../assets/style/variables";
@import '../../assets/style/mixins';

button[class].container{
  position: absolute;
  right: 20px;
  top: 13px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  padding: 0 7px;
  cursor: pointer;
  i{
    svg{
      width: 11px;
      height: 11px;
    }
  }
}
.is-open{
  background: $grey-disabled;
}
