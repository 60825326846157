// Example global color variable
$base-color: #e5e5e5;
$primary-color: rgb(12, 66, 184);
$secondary-color:rgb(184, 12, 84);
$pale-red: #FF75AB;
$light-red: #FFD5E5;
$red: #E12F75;
$require-error-color: #D12A6C;
$border-color-input: rgb(37, 36, 36);
$white: #FFFFFF;
$black: rgb(17, 1, 1);
$dark-grey: #333333;
$light-grey: #EBEBEB;
$grey: #9A9A9A;
$grey-disabled: #AAAAAA;
$blue: #3737ED;
$light-blue: #EFEFFF;
$ultra-blue: #1F1FEB;
$blue-hover: #6464FF;
$light-blue: #DBDBFF;
$box-shadow: rgba(0, 0, 0, 0.05);
$scroll-bar-thumb: #B3B3F8;
$scroll-bar-background: #FCFBFE;
$discharge-btn-hover: #ED4C8B;
$discharge-btn-active: #B31956;
$green-btn-gradient: #128C50;
$green: #149B58;
$approve-green: #107540;
$dark-green:#248653;
$light-green: #B2FDD8;
$Light-gray-range-datepicker: #F5F5FE;
$Light-gray-bg-hover-table: #F6F6FE;
// button
$btn-color: #fff;
$btn--color-outline: #3737ED;
$btn-bg: linear-gradient(180deg, $ultra-blue 0%, $blue 100%);
$btn-login-bg: linear-gradient(180deg, $ultra-blue 0%, $btn--color-outline 100%);
$btn-bg-hover: #3737ED;
$btn-bg-active: linear-gradient(180deg, #101098 0%, #1F1FEB 100%);
$btn-login-bg-active: linear-gradient(180deg, #101098 0%, #0C0CC6 0.01%, #1F1FEB 100%);
$btn-bg-outline: #fff;
$btn-color-disabled: $grey;
$box-shadow-btn: rgba(31, 31, 235, 0.25);
$box-shadow-btn-hover: rgba(55, 55, 237, 0.4);
$box-shadow-btn-active: rgba(16, 16, 152, 0.2);
$box-shadow-btn-outline: rgba(55, 55, 237, 0.1);
$box-shadow-btn-secondary: rgba(55, 55, 237, 0.25);
$box-shadow-btn-secondary-outline: rgba(55, 55, 237, 0.2);
$toggle-shadow:  0px 0px 5px rgba(0, 0, 0, 0.15);
$tooltip-shadow:  0px 0px 20px rgba(0, 0, 0, 0.05);
$invalid-red: #D12A6C;
$lighting-gray: #C4C4C4;
// hover
$tableHover: #F6F6FF;
$orange: #FF5F1B;
$orange-light: #FFDFC8;
$points_icon: #C41BFF;
$scroll-bar-meter: #8EF0C0;
$bg-hover: #6464FF;
