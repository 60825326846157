@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

.modal { width: 360px; }
.container{
  display: flex;
  flex-direction: column;

  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: $dark-grey;
  header{
    margin-top: 16px;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
  }
  p{
    margin-bottom: 34px;
  }
  .thank-for-purchase{
    margin-bottom: 34px;
  }
  .btn-ok{
    width: 120px;
  }
}
