@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

div[class].modal {
  width: 500px;
  height: 214px !important;
  min-height: 200px;
  padding: 32px 40px 48px;
}
.container{
  width: 100%;
  text-align: center;
  color: $dark-grey;
}
header{
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.01em;
}
p {
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}
.container-btn{
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  .btn{
    width: 200px;
    cursor: pointer;
  }
}
