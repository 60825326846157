@import '../../../../assets/style/colors';

.file-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 5px;
}

.file-container:hover {
  background-color: $tableHover;
  border-radius: 8px;
}

.file-name-link {
  display: flex;
  text-decoration: none;
  color: $dark-grey;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.file-name {
  padding-left: 14px;
}

.del-file-icon {
  cursor: pointer;

  .tooltip {
    border-radius: 10px;
    padding: 5px 10px;
    height: 25px;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
    font-size: 12px;
    line-height: 12px;
    background-color: $white;
    color: $dark-grey;
    box-shadow: $tooltip-shadow;
    }

  .tooltip-discharge {
    border: 1px solid $red;
  }
}
