@import '../../../../assets/style/colors';

.modal {
  width: 616px;
  min-height: 415px!important;
  h3 {
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $dark-grey;
    margin-bottom: 40px;
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .item-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: $blue;
    span {
      color: $dark-grey;
      font-weight: 400;
      margin-left: 5px;
    }
  }
  .item-text {
    color: $dark-grey;
    font-weight: 400;
    text-align: justify;
  }
  .black-list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95px;
    height: 21px;
    background-color: #110F30;
    border-radius: 10.5px;
    color: $white;
    font-weight: 700;
    font-size: 8px;
  }
}