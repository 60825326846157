@import '../../assets/style/colors';
@import "../../assets/style/variables";


.container {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-direction: column;

  min-width: 130px;
  min-height: 60px;

  color: $color-dark-blue;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;

  background: $white;
  border: 1px solid $light-grey;
  box-shadow: 0 3px 10px $box-shadow;
  border-radius: 6px;
  z-index: 10100;

  .title {
    padding: 4px 0 10px 0;
    text-align: center;
  }

  .actions {
    display: flex;
    justify-content: center;
  }

  .success,
  .cancel{
    font-weight: bold;
    border: none;
    background: none;
    padding: 0;
    margin: 0;
  }
  .success{
    color: $green;
  }
  .cancel{
    color: $color-crimson;
  }
  .separator{
    width: 1px;
    height: 16px;
    background-color: $lighting-gray;
    //transform: rotate(90deg);
    margin: 0 22px;
  }
}

