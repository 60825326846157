@import '../../../assets/style/mixins';

.container {
 @include container;
  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }

  .main-toggle-bar-wrapper {
    display: flex;
    align-items: flex-end;
    margin-top: 27px;
    margin-bottom: 32px;
    text-align: left;
    justify-content: space-between;
    width: auto;

    & > div {
      width: fit-content;
      margin: 0;
    }
  }

  .personal-info {
    margin-top: 24px;
  }
}