@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import '../../../../../assets/style/mixins';

.container {
  @include container;
  position: relative;

  .header {
    @include top-header;
    h1{
      @include top-header-h1
    }
  }
}
.block-go-back{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 20px;
  font-size: 12px;
  margin-top: 26px;
  padding-bottom: 26px;
}
.go-back{
  background: none;
  color:$blue;
  padding: 0;
  cursor: pointer;
  svg{
    margin-bottom: -2.5px;
  }
  svg path{
    stroke: $blue;
  }
  .go-back-text{
    margin-left: 8px;
    font-size: 14px;
  }
}
.content-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.picture-container{
  display: flex;
  justify-content: flex-end;
  width: 35%;
  .picture {
    position: relative;
    background-color: $light-grey;
    border-radius: 14px;
    margin-right: 28px;
    width: 265px;
    height: 270px;
  }
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .center {
    position: absolute;
    cursor: pointer;
    top: calc(50% - 50px);
    left: calc(50% - 15px);
    margin: 0;
  }
  .right {
    position: absolute;
    cursor: pointer;
    top: 80%;
    left: 80%;
    margin: 0;
    // z-index: 9999;
  }
  .info {
    padding: 20px 0;
    position: absolute;
    text-align: center;
    top: calc(50% - 25px);
    width: 100%;
    color: $grey;
    font-size: 12px;
    line-height: 18px;
  }
}
.invalid {
  border: 1px solid $invalid-red;
}

.information{
  width: 65%;
  display: flex;
  flex-direction: column;

}
.information-row{
  margin-bottom: 20px;
  max-width: 75%;
  display: flex;
  flex-direction: column;
  .input-name{
    width: 100%
  }
  .input-categoryId{
    width: 75%;
  }
  .input-price{
    width: 25%;
    margin-left: 24px;
  }
  .description{
    textarea {
      margin-top: 7px;
      width: 100%;
      height: 96px;
    }
  }
  .label-for-field{
    display: block;
    margin: 0;
    font-size: 12px;
    line-height: 18px;
    color: $color-gray;
    text-align: left;
  }
  .input{
    margin-top: 7px;
  }
}
.information-row:nth-child(2){
  flex-direction: row;
}
.btn-container{
  display: flex;
  justify-content: flex-end;
  max-width: 75%;
  .btn{
    margin-left: 20px;
    width: 200px;
    cursor: pointer;
  }
}


