@import '../../../../../assets/style/colors';
@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/mixins';

div[class].modal {
  width: 620px;
  height: 400px;
  min-height: 200px;
}
.form {
  max-height: 200px;
  height: 400px;

  header {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: $color-dark-blue;
    margin-bottom: 20px;
  }

  .div-wrapper{
    display: flex;
    justify-content: space-between;
    width: 150px;
  }
  .div-wrapper button{
    background-color: inherit;
    outline: 0 !important;
  }
  .form-row {
    display: flex;
    justify-content: space-between;
    .users-select-label{
      font-size: 12px;
      color: $color-gray;
    }
    .users-select{
      width: 375px;
    }
  }

  .input-wrapper {
    width: 88px;
    margin: 0 6px 0;
  }
  .input-label {
    margin-top: 5px;
    font-size: 12px;
    color: $grey;
    padding-bottom: 7px;
  }

  .red-note {
    padding-left: 5px;
    display: inline;
    color: $invalid-red
  }

  .balance-info {
    color: $dark-grey;
    padding-top: 26px;
    font-weight: bold;
    font-size: 14px;
  }

  .current-balance{
    margin-left: 10px;
    color: $green;
    font-size: 18px;
  }

  .comments-wrapper {
    padding-top: 20px;
    margin-bottom: 25px;
  }

  .form-row-btn {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 15px;
  }

  .div-btn-wrapper {
    width: 210px;
  }
}

.confirm-modal {
  text-align: center;

  header {
    font-weight: bold;
    font-size: 20px;
    color: $color-dark-blue;
  }
  section {
    padding: 20px 0 40px;
  }
  .button-content {
    padding: 0 30px;
  }
}

.comments {
  textarea {
    height: 110px !important;
  }
}

