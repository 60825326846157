@import '../../../../../assets/style/colors';
@import "../../../../../assets/style/variables";
@import "../../../../../assets/style/mixins";

.container {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 350px;
  justify-content: flex-start;
  max-height: 450px;
  overflow-x: hidden;
}
.header{
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: $color-dark-blue;
  margin-bottom: 25px;
}
.inputs-wrapper {
  min-height: 250px;
  max-height: 350px;
  overflow: auto;
  padding-bottom: 20px;
}

.row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 5px;
}

.field {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 33%;
  margin-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  &.date {
    width: 95px;
  }

  &.remove {
    cursor: pointer;
    width: 20px;
    align-self: center;
  }
}

.input-label {
  font-size: 12px;
  color: $grey;
  text-align: left;
  width: 25%;
}

.buttons {
  margin-top: auto;
  display: flex;
  justify-content: flex-end;
}

.buttons .btn {
  margin-right: 30px;
  width: 170px;
}
